import { graphql, useStaticQuery } from 'gatsby'
import { useEffect, useState } from 'react'
import useSearch from '../../hooks/useSearch'
import {
  getAllNews,
  getAllPokerBlogs,
  getAllCarromBlogs,
  getAllRummyBlogs,
  getAllPoolBlogs,
  getAllChessBlogs,
  getAllEarnMoneyBlogs,
  getAllGamblingBlogs,
  getAllListingsBlogs,
  getAllRealMoneyGamesBlogs,
  getAllAuthorsBlogs,
  getAllCricketBlogs,
  getAllRmgPokerRealMoneyBlogs,
  getAllRmgRummyRealMoneyBlogs,
  getAllRummyCouponsBlogs,
  getAllPokerCouponsBlogs,
  // eslint-disable-next-line
  // @ts-ignore
} from '../../lib/ghost-blogs'

interface Props {
  tags: string[]
}
// filtering out vernacular blog posts & staging posts from nodal page
const useCommonFunctionality = ({ tags }: Props) => {
  const data = useStaticQuery(graphql`
    query {
      allGhostPost(
        sort: { order: DESC, fields: [published_at] }
        filter: {
          tags: {
            elemMatch: {
              slug: {
                nin: [
                  "hash-lang_hindi"
                  "hash-lang_telugu"
                  "hash-lang_gujarati"
                  "hash-lang_marathi"
                  "hash-lang_tamil"
                  "hash-staging"
                ]
              }
            }
          }
        }
      ) {
        edges {
          node {
            id
            slug
            title
            reading_time
            published_at
            updated_at(formatString: "MMMM DD, YYYY")
            html
            feature_image
            primary_author {
              name
            }
            tags {
              name
              slug
            }
          }
        }
      }
    }
  `)

  const [searchTermNews, setSearchTermNews] = useState('')
  const [searchTermPoker, setSearchTermPoker] = useState('')
  const [searchTermCarrom, setSearchTermCarrom] = useState('')
  const [searchTermRummy, setSearchTermRummy] = useState('')
  const [searchTermPool, setSearchTermPool] = useState('')
  const [searchTermChess, setSearchTermChess] = useState('')
  const [searchTermEarnMoney, setSearchTermEarnMoney] = useState('')
  const [searchTermGambling, setSearchTermGambling] = useState('')
  const [searchTermListings, setSearchTermListings] = useState('')
  const [searchTermRealMoneyGames, setSearchTermRealMoneyGames] = useState('')
  const [searchTermAuthors, setSearchTermAuthors] = useState('')
  const [searchTermCricket, setSearchTermCricket] = useState('')
  const [searchTermRummyRMG, setSearchTermRummyRMG] = useState('')
  const [searchTermPokerRMG, setSearchTermPokerRMG] = useState('')
  const [blogs, setBlogs] = useState<any>([])
  const [allNews, setAllNews] = useState<any>([])
  const [allPokerBlogs, setAllPokerBlogs] = useState<any>([])
  const [allCarromBlogs, setAllCarromBlogs] = useState<any>([])
  const [allRummyBlogs, setAllRummyBlogs] = useState<any>([])
  const [allPoolBlogs, setAllPoolBlogs] = useState<any>([])
  const [allChessBlogs, setAllChessBlogs] = useState<any>([])
  const [allEarnMoneyBlogs, setAllEarnMoneyBlogs] = useState<any>([])
  const [allGamblingBlogs, setAllGamblingBlogs] = useState<any>([])
  const [allListingsBlogs, setAllListingsBlogs] = useState<any>([])
  const [allRealMoneyGamesBlogs, setAllRealMoneyGamesBlogs] = useState<any>([])
  const [allAuthorsBlogs, setAllAuthorsBlogs] = useState<any>([])
  const [allCricketBlogs, setAllCricketBlogs] = useState<any>([])
  const [allRummyRMGBlogs, setAllRummyRMGBlogs] = useState<any>([])
  const [allPokerRMGBlogs, setAllPokerRMGBlogs] = useState<any>([])
  const [indexFields] = useState(['title'])

  useEffect(() => {
    const an = getAllNews(data.allGhostPost.edges)
    const apb = getAllPokerBlogs(data.allGhostPost.edges)
    const acb = getAllCarromBlogs(data.allGhostPost.edges)
    const arb = getAllRummyBlogs(data.allGhostPost.edges)
    const aplb = getAllPoolBlogs(data.allGhostPost.edges)
    const achb = getAllChessBlogs(data.allGhostPost.edges)
    const earnMoneyBlogs = getAllEarnMoneyBlogs(data.allGhostPost.edges)
    const gamblingBlogs = getAllGamblingBlogs(data.allGhostPost.edges)
    const listingsBlogs = getAllListingsBlogs(data.allGhostPost.edges)
    const realMoneyGamesBlogs = getAllRealMoneyGamesBlogs(
      data.allGhostPost.edges
    )
    const authorsBlogs = getAllAuthorsBlogs(data.allGhostPost.edges)
    const cricketBlogs = getAllCricketBlogs(data.allGhostPost.edges)

    const rummyCouponBlogs = getAllRummyCouponsBlogs(
      data.allGhostPost.edges.filter((e: { node: { tags: any[] } }) =>
        e.node.tags.some(obj => obj.name === 'display/Rummy')
      )
    )

    const pokerCouponsBlogs = getAllPokerCouponsBlogs(
      data.allGhostPost.edges.filter((e: { node: { tags: any[] } }) =>
        e.node.tags.some(obj => obj.name === 'display/Poker')
      )
    )

    const rummyRMGBlogsOnly = getAllRmgRummyRealMoneyBlogs(
      data.allGhostPost.edges.filter((e: { node: { tags: any[] } }) =>
        e.node.tags.some(obj => obj.name === 'display/Rummy')
      )
    )
    const pokerRMGBlogsOnly = getAllRmgPokerRealMoneyBlogs(
      data.allGhostPost.edges.filter((e: { node: { tags: any[] } }) =>
        e.node.tags.some(obj => obj.name === 'display/Poker')
      )
    )

    const rummyRMGBlogs = rummyRMGBlogsOnly.concat(rummyCouponBlogs)

    const pokerRMGBlogs = pokerRMGBlogsOnly.concat(pokerCouponsBlogs)

    const blgs = data.allGhostPost.edges
      .map((b: any) => b.node)
      .filter((e: any) => {
        return e.tags.reduce((has: boolean, t: any) => {
          if (has) return has

          return tags.includes(t.name)
        }, false)
      })

    setBlogs(blgs)
    setAllNews(an)
    setAllPokerBlogs(apb)
    setAllCarromBlogs(acb)
    setAllRummyBlogs(arb)
    setAllPoolBlogs(aplb)
    setAllChessBlogs(achb)
    setAllEarnMoneyBlogs(earnMoneyBlogs)
    setAllGamblingBlogs(gamblingBlogs)
    setAllListingsBlogs(listingsBlogs)
    setAllRealMoneyGamesBlogs(realMoneyGamesBlogs)
    setAllAuthorsBlogs(authorsBlogs)
    setAllCricketBlogs(cricketBlogs)
    setAllRummyRMGBlogs(rummyRMGBlogs)
    setAllPokerRMGBlogs(pokerRMGBlogs)
  }, [tags, data.allGhostPost.edges])

  const searchNews = useSearch({
    searchName: 'title',
    documents: allNews,
    indexFields,
  })

  const searchPoker = useSearch({
    searchName: 'title',
    documents: allPokerBlogs,
    indexFields,
  })

  const searchCarrom = useSearch({
    searchName: 'title',
    documents: allCarromBlogs,
    indexFields,
  })

  const searchRummy = useSearch({
    searchName: 'title',
    documents: allRummyBlogs,
    indexFields,
  })

  const searchPool = useSearch({
    searchName: 'title',
    documents: allPoolBlogs,
    indexFields,
  })

  const searchChess = useSearch({
    searchName: 'title',
    documents: allChessBlogs,
    indexFields,
  })

  const searchEarnMoney = useSearch({
    searchName: 'title',
    documents: allEarnMoneyBlogs,
    indexFields,
  })

  const searchGambling = useSearch({
    searchName: 'title',
    documents: allGamblingBlogs,
    indexFields,
  })

  const searchListings = useSearch({
    searchName: 'title',
    documents: allListingsBlogs,
    indexFields,
  })

  const searchRealMoneyGames = useSearch({
    searchName: 'title',
    documents: allRealMoneyGamesBlogs,
    indexFields,
  })

  const searchAuthors = useSearch({
    searchName: 'title',
    documents: allAuthorsBlogs,
    indexFields,
  })

  const searchCricket = useSearch({
    searchName: 'title',
    documents: allCricketBlogs,
    indexFields,
  })

  const searchRummyRMG = useSearch({
    searchName: 'title',
    documents: allRummyRMGBlogs,
    indexFields,
  })

  const searchPokerRMG = useSearch({
    searchName: 'title',
    documents: allPokerRMGBlogs,
    indexFields,
  })

  let filteredBlogs = blogs
  if (searchTermNews) {
    filteredBlogs = searchNews.search(searchTermNews)
  }
  if (searchTermPoker) {
    filteredBlogs = searchPoker.search(searchTermPoker)
  }
  if (searchTermCarrom) {
    filteredBlogs = searchCarrom.search(searchTermCarrom)
  }
  if (searchTermRummy) {
    filteredBlogs = searchRummy.search(searchTermRummy)
  }
  if (searchTermPool) {
    filteredBlogs = searchPool.search(searchTermPool)
  }
  if (searchTermChess) {
    filteredBlogs = searchChess.search(searchTermChess)
  }
  if (searchTermEarnMoney) {
    filteredBlogs = searchEarnMoney.search(searchTermEarnMoney)
  }
  if (searchTermGambling) {
    filteredBlogs = searchGambling.search(searchTermGambling)
  }
  if (searchTermListings) {
    filteredBlogs = searchListings.search(searchTermListings)
  }
  if (searchTermRealMoneyGames) {
    filteredBlogs = searchRealMoneyGames.search(searchTermRealMoneyGames)
  }

  if (searchTermAuthors) {
    filteredBlogs = searchAuthors.search(searchTermAuthors)
  }

  if (searchTermCricket) {
    filteredBlogs = searchCricket.search(searchTermCricket)
  }

  if (searchTermRummyRMG) {
    filteredBlogs = searchRummyRMG.search(searchTermRummyRMG)
  }

  if (searchTermPokerRMG) {
    filteredBlogs = searchPokerRMG.search(searchTermPokerRMG)
  }

  return {
    searchTermNews,
    searchTermPoker,
    searchTermCarrom,
    searchTermRummy,
    searchTermPool,
    searchTermChess,
    searchTermEarnMoney,
    searchTermGambling,
    searchTermListings,
    searchTermRealMoneyGames,
    searchTermAuthors,
    searchTermCricket,
    searchTermRummyRMG,
    searchTermPokerRMG,
    setSearchTermNews,
    setSearchTermPoker,
    setSearchTermCarrom,
    setSearchTermRummy,
    setSearchTermPool,
    setSearchTermChess,
    setSearchTermEarnMoney,
    setSearchTermGambling,
    setSearchTermListings,
    setSearchTermRealMoneyGames,
    setSearchTermAuthors,
    setSearchTermCricket,
    setSearchTermRummyRMG,
    setSearchTermPokerRMG,
    blogs: filteredBlogs,
  }
}

export default useCommonFunctionality
