import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { isAndroid, isIOS } from '../formatForBigQuery'
import NewsList from '../components/News/NewsList'
import { NEWS_TAG_IDENTIFIER } from '../components/Blogs/constants'
import useCommonFunctionality from '../components/Blogs/useCommonFunctionality'

interface Props {
  newsTitle: string
  updatedDate: string
  shareButtons: React.ReactNode
  topBanner: React.ReactNode
  tags: string[]
  prefix: string
  postContent: React.ReactNode
  currentSlug: string
}

const NewsLayout: React.FC<Props> = ({
  newsTitle,
  updatedDate,
  shareButtons,
  topBanner,
  tags,
  prefix,
  postContent,
  currentSlug,
}) => {
  const [platform, setPlatform] = useState('web') // default set to web
  const { blogs } = useCommonFunctionality({ tags })

  const blogIdentifier = NEWS_TAG_IDENTIFIER
  const [selectedTag, setSelectedTag] = useState(blogIdentifier)

  useEffect(() => {
    setSelectedTag(blogIdentifier)
  }, [blogIdentifier])

  let filteredBlogs = blogs
  if (selectedTag !== blogIdentifier) {
    filteredBlogs = filteredBlogs.filter((b: unknown) =>
      b.tags.reduce((has: boolean, t: unknown) => {
        if (has) return has
        return t.name === selectedTag
      }, false)
    )
  }

  useEffect(() => {
    if (isAndroid()) {
      setPlatform('android')
    }
    if (isIOS()) {
      setPlatform('ios')
    }
  }, [platform])

  const NewsSimilarContent = filteredBlogs
    ?.filter((item: { slug: string }) => item.slug !== currentSlug)
    .map((b: unknown) => {
      return (
        <>
          <NewsList
            key={b.id}
            title={b.title}
            slug={b.slug}
            prefix={b.prefix || prefix}
            feature_image={b.feature_image}
            publishing_date={new Date(b.published_at).toLocaleDateString()}
            html={b.html}
          />
        </>
      )
    })

  return (
    <>
      <div className="blog newsBlock">
        <Container>
          <div className="newsPostContent">
            <h1>{newsTitle}</h1>
            <div className="post-meta">
              <span>Published on: {updatedDate}</span>
              <span>Posted By: GetMega Editorial Team</span>
            </div>
            {topBanner}
            <div id="post-contents">
              <Row>
                <Col lg={8}>{postContent}</Col>
                <Col lg={4}>
                  <div className="similarNews">
                    <div className="relatedNewsTitle">Related News</div>
                    {NewsSimilarContent}
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          <div className="newsPostContent">
            <div className="post-like-languages">{shareButtons}</div>
          </div>
        </Container>
      </div>
    </>
  )
}

export default NewsLayout
