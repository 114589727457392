import React from 'react'

interface Props {
  articleSections: string[]
  visibleSections: boolean[]
}

const ArticleSections: React.FC<Props> = ({
  articleSections,
  visibleSections,
}) => {
  return (
    <div className="other-articles-wrap">
      <div className="other-articles">
        {articleSections.map((a: any, i) => {
          return (
            <div
              key={a}
              className={`o-article ${visibleSections[i] ? 'selected' : ''}`}
            >
              {a}
            </div>
          )
        })}
      </div>
      {/* <div className="chevron">
        <OverlayTrigger
          rootClose
          trigger="click"
          placement="bottom-end"
          overlay={
            <Popover id="all-games-popover">
              <Popover.Content className="all-games-popover">
                <div className="games-links">
                  <span>Test</span>
                </div>
              </Popover.Content>
            </Popover>
          }
        >
          <Img
            fluid={data.chevron.childImageSharp.fluid}
            alt=""
            style={{ width: '13px' }}
          />
        </OverlayTrigger>
      </div> */}
    </div>
  )
}

export default ArticleSections
