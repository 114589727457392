import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { debounce } from 'lodash'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { useLocation } from '@reach/router'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Breadcrumb } from '../components/Breadcrumbs/Breadcrumbs'
import ArticleSections from './ArticleSections'
import BlogSearchInput from '../components/Blogs/BlogSearchInput'
import NewsList from '../components/News/NewsList'
import useSearch from '../hooks/useSearch'
import NewsLayout from './newsLayouts'
import ShareButtons from '../components/Share/Share'
import NEWS_PAGES from '../news-tag'
import {
  generateBreadcrumbsSchema,
  generateNewsSchema,
} from '../lib/generate-schema'
import getBreadcrumbs from './getBreadcrumbs'
import './NewsTemplate.scss'
import { modifyHtmlBeforeRendering } from '../utils/modifyATag'

const NewsTemplate: React.FC = (context: any) => {
  const { data, pageContext } = context
  const post = data.currPost
  post.html = modifyHtmlBeforeRendering(post.html)
  const { prefix, allNews } = pageContext

  const fetchBlog = allNews
  const location = useLocation()
  const { title } = post
  const URL = location.href

  const sections: string[] = post.excerpt.split('\n').filter((s: string) => s)
  const [visibleSections] = useState(Array(sections.length).fill(false))

  const [searchTerm, setSearchTerm] = useState('')
  const [blogsList, setBlogsList] = useState<any>([])
  const [searchVisible, setSearchVisible] = useState(false)

  const search = useSearch({
    searchName: 'title',
    documents: fetchBlog,
    indexFields: ['title'],
  })

  useEffect(() => {
    if (searchTerm) {
      setBlogsList(search.search(searchTerm).slice(0, 10))
    } else {
      setBlogsList([])
    }
  }, [search, searchTerm])

  const updateSearchTerm = debounce((s: string) => {
    setSearchTerm(s)
  }, 500)
  const breadcrumbs: Breadcrumb[] = getBreadcrumbs(`/${prefix}`, post)

  // adding custom top banner if present, otherwise use regular Featured Images.
  const topBanner = post.feature_image ? (
    <div className="article-poster-image">
      <img src={post.feature_image} alt={post.title} width="730" height="311" />
    </div>
  ) : (
    <div className="article-poster-image">
      <StaticImage
        loading="eager"
        src="../images/featured-images/other-blogs.jpg"
        alt="getmega"
        width={730}
        height={311}
      />
    </div>
  )

  const shareButtons = (
    <>
      <ShareButtons title={title} url={URL} />
    </>
  )

  const postContent = (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: post.html,
        }}
      />
    </>
  )

  return (
    <Layout
      blogPage
      showSearchFunc={setSearchVisible}
      showSearchBar={searchVisible}
    >
      <SEO
        title={post.meta_title || post.title}
        description={post.meta_description}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
        newsSchema={generateNewsSchema({
          url: `${data.site.siteMetadata.siteUrl}/${prefix}/${post.slug}/`,
          headline: post.title,
          mainEntityOfPage: `${data.site.siteMetadata.siteUrl}/${prefix}/${post.slug}/`,
          articleBody: post.excerpt,
          image: post.feature_image,
          datePublished: post.published_at,
        })}
      />
      <Helmet
        meta={[
          {
            name: 'twitter:image',
            content: post.feature_image
              ? post.feature_image
              : data.site.siteMetadata.siteUrl + data.defaultOGImage.publicURL,
          },
          {
            property: 'og:image',
            content: post.feature_image
              ? post.feature_image
              : data.site.siteMetadata.siteUrl + data.defaultOGImage.publicURL,
          },
        ]}
      />
      <article id="News">
        {post.excerpt ? (
          <div className="articles-list">
            <Container>
              <Row>
                <Col className="list">
                  <ArticleSections
                    articleSections={post.excerpt
                      .split('\n')
                      .filter((s: string) => s)}
                    visibleSections={visibleSections}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        ) : null}

        {searchVisible && (
          <div className="blog-list-wrap">
            <Container>
              <BlogSearchInput onChange={s => updateSearchTerm(s)} />
              <div className="blogs-list">
                {blogsList.map((b: any) => {
                  return (
                    <NewsList
                      key={b.id}
                      title={b.title}
                      slug={b.slug}
                      prefix={b.prefix || prefix}
                      feature_image={b.feature_image}
                      publishing_date={new Date(
                        b.published_at
                      ).toLocaleDateString()}
                      html={b.html}
                    />
                  )
                })}
              </div>
            </Container>
          </div>
        )}
        <NewsLayout
          postContent={postContent}
          newsTitle={post.title}
          updatedDate={post.updated_at}
          shareButtons={shareButtons}
          topBanner={topBanner}
          tags={NEWS_PAGES}
          prefix={`/${process.env.GATSBY_CMS_NEWS_PREFIX}` || '/'}
          currentSlug={post.slug}
        />
      </article>
    </Layout>
  )
}

export default NewsTemplate
export const postQuery = graphql`
  query ($slug: String!) {
    currPost: ghostPost(slug: { eq: $slug }) {
      title
      slug
      feature_image
      html
      excerpt
      custom_excerpt
      meta_title
      meta_description
      tags {
        slug
      }
      published_at
      reading_time
      updated_at(formatString: "MMMM DD, YYYY")
      created_at
      primary_author {
        name
      }
    }
    defaultOGImage: file(
      relativePath: { eq: "share-blog-post/getmega-default.png" }
    ) {
      publicURL
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
