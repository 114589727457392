import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'

interface Props {
  title: string
  feature_image?: any
  publishing_date: string
  html: string
  slug: string
  prefix: string
}

const NewsList: React.FC<Props> = ({
  title,
  feature_image,
  publishing_date,
  html,
  slug,
  prefix,
}) => {
  const [newsDescription, setNewsDescription] =
    useState<React.ReactNode | null>([])

  useEffect(() => {
    const postContent = html.replace(/<\/?[^>]+(>|$)/g, '')
    setNewsDescription(postContent.substring(0, 45))
  }, [html])

  return (
    <Link to={`${prefix}/${slug}/`}>
      <div className="news">
        <div className="news-preview">
          {feature_image ? (
            <img
              loading="lazy"
              src={feature_image}
              alt={title}
              width="293"
              height="235"
            />
          ) : (
            <span className="no-preview-image">Mega</span>
          )}
        </div>
        <div className="news-title-wrap">
          <h2 className="news-title">{title}</h2>
          <p className="news-content">{newsDescription}...</p>
          <span className="news-date">{publishing_date}</span>
        </div>
      </div>
    </Link>
  )
}

export default NewsList
